<template>
  <div>
    <el-dialog
      @close="closePanel"
      width="45%"
      title="新增合成信息"
      :visible.sync="dialogShow"
      :close-on-click-modal="modal"
    >
      <el-form
        :model="form"
        label-width="110px"
        :rules="rules"
        ref="myForm"
        required
      >
        <el-form-item label="合成材料：" prop="needNFTID">
          <el-select placeholder="请选择合成藏品" v-model="form.needNFTID">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.NFTName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="数量" prop="count">
          <el-input
            placeholder="请输入数量"
            v-model="form.count"
            type="Number"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="btn">
        <el-button @click="closePanel" size="small">取 消</el-button>
        <el-button type="primary" @click="doValidate" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { NftQuery } from "@/api/module/nft";

let Brarules = {
  needNFTID: [
    {
      required: true,
      message: "请选择合成材料",
      trigger: "change",
    },
  ],
  count: [
    {
      required: true,
      message: "请输入数量",
      trigger: "blur",
    },
  ],
};

export default {
  name: "composePanel",
  props: {
    dialogShow: Boolean,
  },
  data() {
    return {
      modal: false,
      options: [],
      form: {
        needNFTID: "",
        count: "",
      },
      rules: Brarules,
    };
  },
  created() {
    this.getPackage();
  },
  methods: {
    async getPackage() {
      let query = `?pagination=false&page=1&pageSize=10&status=3`;
      const r = await NftQuery(query);
      this.options = r.list;
    },
    doValidate() {
      let f = this.form;
      let oData = {
        needNFTID: f.needNFTID,
        count: Number(f.count),
      };
      this.$refs.myForm.validate(async (val) => {
        if (val) {
          //   全局验证通过;
          this.$message({
            type: "success",
            message: "添加成功",
            duration: 1300,
          });
          this.$emit("update:dialogShow", false);
          //调用父组件的方法
          this.$emit("update", oData);
          this.$refs["myForm"].resetFields(); // 在初始化所有字段
        } else {
          //全局验证不通过
        }
      });
    },
    closePanel() {
      this.form = {
        needNFTID: "",
        count: "",
      };
      this.$emit("update:dialogShow", false);
      this.$refs.myForm.resetFields();
    },
  },
};
</script>

